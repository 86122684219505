<template>
    <div class="body">
        <div v-for="(item, index) in data.items" :key="index" class="content">
            <div class="text">
                <span class="description" v-if="item.description">
                    {{ item.description }}
                </span>
            </div>
            <div class="image" v-if="item.cover">
                <img :src="item.cover" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
    },
    created() {
        console.log(this.data);
    },
};
</script>

<style lang="scss" scoped>
.body {
    width: 100%;
    margin: 0 auto;
    max-width: 2000px;
    padding-top: 50px;
}

@media screen and (min-width: 700px) {
    .content {
        display: flex;
        width: 80%;
        margin: 0 0 0 auto;
    }
}

.image {
    width: 67%;
}

.image img {
    width: 100%;
    display: block;
}

.text {
    width: 33%;
    margin-top: 50px;
}

.description {
    padding-bottom: 30px;
    font-size: 20px;
    line-height: 35px;
    white-space: pre-line;
}

@media screen and (max-width: 700px) {
    .body {
        margin: 0 auto;
        padding-top: 50px;
    }

    .content {
        width: 100%;
    }

    .image {
        margin: 0 auto;
        width: 94%;
        margin-top: 10px;
    }

    .image img {
        width: 100%;
        display: block;
    }

    .text {
        width: 94%;
        margin: 0 auto;
    }

    .description {
        font-size: 18px;
        line-height: 30px;
        color: #666;
    }
}
</style>
